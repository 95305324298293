<template>
  <div class="register-step2">
    <top-bar back backroute="auth" is-auth :img="logo" />
    <div class="container">
      <div class="title"><t name="2.6" /></div>
      <div class="content"><t name="3.14" /></div>
      <n-input :title="$app.secure.clean($n.t('3.26'))" v-bind="$form.input('moreAddress')"></n-input>
      <div class="wrap">
        <div v-for="item in cities" :key="item.id" class="">
          <div class="item"  @click="open(item.id)">
            <div class="left">
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="right">
              <div class="right papa"><n-icon icon="right-arrow" /></div>
            </div>
          </div>
          <div v-if="item.id === opened">
            <div v-for="child in item.children"
                 :key="child.id" class="item child" @click="selectCity(child.id)">
              <div class="left">
                <div class="title">{{ child.name }}</div>
              </div>
              <div class="right">
                <div class="right"><n-icon v-if="selectedCity === child.id" icon="selected" /></div>
              </div>
            </div>
          </div>
        </div>
        <n-button color="yellow" :disabled="!selectedCity" @click="next" wide><t name="16.7" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageFinishRegister',
  data() {
    return {
      logo, img,
      cities: [],
      opened: null,
      selectedCity: null
    }
  },
  computed: {
    role() {
      return this.$route.query.role
    },
    phone() {
      return this.$route.query.phone
    },
    code() {
      return this.$route.query.code
    },
  },
  created() {
    this.$form.init({})
    this.loadCities()
  },
  methods: {
    open(id) {
      if (this.opened === id) {
        this.opened = null
      } else {
        this.opened = id
      }
    },
    selectCity(cityId) {
      this.selectedCity = cityId
    },
    next() {
      const data = {
        role: Number(this.role),
        phone: this.phone,
        code: this.code,
        cityId: this.selectedCity,
        moreAddress: this.$form.get('moreAddress')
      }
      this.$n.saveLocal('regData', data)
      this.$router.push({ name: 'register.enterName', })
    },
    loadCities() {
      this.$var('load', true)
      $api.cities.get().with('children').then((response) => {
        this.cities = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    getCode() {
      if (this.$form.check()) {
        const role = this.role || ''
        this.$router.push({ name: 'confirm.code', query: { role: role, phone: this.$form.get('phone'), }, })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2{
  padding-top: 90px;
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .wrap {
    margin-top: 20px;
    padding-bottom: 30px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
      padding: 5px;
      margin-bottom: 10px;
      min-height: 44px;
      &.child {
        padding-left: 25px;
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        &.papa {
          transform: rotate(90deg);
        }
      }
      .left {
        display: flex;
        align-items: center;
        justify-content: center;

        .title{
          font-weight: 400;
          margin-bottom: 0;
          font-size: 18px;
          color: #102E4F;
        }
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }
  .content {
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input{
    margin-bottom: 16px;
  }

  .n-button{
    position: relative;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
